<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
     <!-- <PdfViewer /> -->
      <!-- <Flipbook /> -->
       <router-view />
  </div>
</template>

<script>
import 'core-js/features/array/at' 

// import PdfViewer from './components/PdfViewer.vue'
// import Flipbook from './components/Flipbook.vue'

export default {
  name: 'App',
  // components: {
  //   // HelloWorld,
  //   PdfViewer,
  //   Flipbook
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

html, body {
  overflow: hidden;
  height: 100%;
}
</style>
