var jWeixin = require('jweixin-module')  

var config  ={
	title: 'CKD早期诊疗热点争议-九月刊',
	desc: 'HIFI肾立场-慢性肾脏病高影响力交流场项目-中国健康促进基金会',
	link: "https://hifi-book.alicebj.cn/",
	imgUrl: 'https://nas.alicebj.cn:14032/hifi-slc/book/logo.png'
}

// 配置微信分享
export function configShare(shareData) {
	jWeixin.config({
		debug: false,
		appId: shareData.appId,
		timestamp: shareData.timestamp,
		nonceStr: shareData.nonceStr,
		signature: shareData.signature,
		jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 根据需要配置更多的分享接口
	});
	jWeixin.error(function(res){
		// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
		console.error(res)
	});
	jWeixin.ready(() => {
		console.warn("js ready")
		// 分享到朋友圈
		jWeixin.updateTimelineShareData({
			title: config.title, // 分享标题
			link: config.link, // 分享链接
			imgUrl: config.imgUrl, // 分享图标
			success: function(r) {
				// 用户确认分享后执行的回调函数
				console.log(r)
				console.warn("share ok")
			},
			cancel: function() {
				// 用户取消分享后执行的回调函数
			}
		});

		// 分享给朋友
		jWeixin.updateAppMessageShareData({
			title: config.title, // 分享标题
			desc: config.desc, // 分享描述
			link: config.link, // 分享链接
			imgUrl: config.imgUrl, // 分享图标
			success: function(r) {
				// 用户确认分享后执行的回调函数
				console.log(r)
				console.warn("share ok")
			},
			cancel: function() {
				// 用户取消分享后执行的回调函数
			}
		});
	});
}
