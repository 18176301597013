
const jQuery = require('jquery');
/**  
 *  Secondary development based on turn.js.
 */
(function(h) {
    function N(a, b, c) {
        if (c[0] && "object" != typeof c[0]) {
            if (b[c[0]])
                return b[c[0]].apply(a, Array.prototype.slice.call(c, 1));
            throw t(c[0] + " is not a method or property");
        }
        return b.init.apply(a, c)
    }
    function p(a, b, c, d) {
        return {
            css: {
                position: "absolute",
                top: a,
                left: b,
                overflow: d || "hidden",
                zIndex: c || "auto"
            }
        }
    }
    function W(a, b, c, d, e) {
        var f = 1 - e,
            k = f * f * f,
            r = e * e * e;
        return m(Math.round(k * a.x + 3 * e * f * f * b.x + 3 * e * e * f * c.x + r * d.x), Math.round(k * a.y + 3 * e * f * f * b.y + 3 * e * e * f * c.y + r * d.y))
    }
    function m(a, b) {
        return {
            x: a,
            y: b
        }
    }
    function I(a, b, c) {
        return C && c ? " translate3d(" + a + "px," + b + "px, 0px) " : " translate(" + a + "px, " + b + "px) "
    }
    function J(a) {
        return " rotate(" + a + "deg) "
    }
    function q(a, b) {
        return Object.prototype.hasOwnProperty.call(b, a)
    }
    function X() {
        for (var a = ["Moz", "Webkit", "Khtml", "O", "ms"], b = a.length, c = ""; b--;)
            a[b] + "Transform" in document.body.style && (c = "-" + a[b].toLowerCase() + "-");
        return c
    }
    function S(a, b, c, d, e) {
        var f,
            k = [];
        if ("-webkit-" == A) {
            for (f = 0; f < e; f++)
                k.push("color-stop(" + d[f][0] + ", " + d[f][1] + ")");
            a.css({
                "background-image": "-webkit-gradient(linear, " + b.x + "% " + b.y + "%," + c.x + "% " + c.y + "%, " + k.join(",") + " )"
            })
        } else {
            b = {
                x: b.x / 100 * a.width(),
                y: b.y / 100 * a.height()
            };
            c = {
                x: c.x / 100 * a.width(),
                y: c.y / 100 * a.height()
            };
            var r = c.x - b.x;
            f = c.y - b.y;
            var h = Math.atan2(f, r),
                l = h - Math.PI / 2,
                l = Math.abs(a.width() * Math.sin(l)) + Math.abs(a.height() * Math.cos(l)),
                r = Math.sqrt(f * f + r * r);
            c = m(c.x < b.x ? a.width() : 0, c.y < b.y ? a.height() : 0);
            var g = Math.tan(h);
            f = -1 / g;
            g = (f * c.x - c.y - g * b.x + b.y) / (f - g);
            b = Math.sqrt(Math.pow(g - b.x, 2) + Math.pow(f * g - f * c.x + c.y - b.y, 2));
            for (f = 0; f < e; f++)
                k.push(" " + d[f][1] + " " + 100 * (b + r * d[f][0]) / l + "%");
            a.css({
                "background-image": A + "linear-gradient(" + -h + "rad," + k.join(",") + ")"
            })
        }
    }
    function u(a, b, c) {
        a = h.Event(a);
        b.trigger(a, c);
        return a.isDefaultPrevented() ? "prevented" : a.isPropagationStopped() ? "stopped" : ""
    }
    function t(a) {
        function b(a) {
            this.name = "TurnJsError";
            this.message = a
        }
        b.prototype = Error();
        b.prototype.constructor = b;
        return new b(a)
    }
    function D(a) {
        var b = {
            top: 0,
            left: 0
        };
        do b.left += a.offsetLeft,
        b.top += a.offsetTop;
        while (a = a.offsetParent);
        return b
    }
    var C,
        Y,
        A = "FLBOOK.COM.CN",
        O = Math.PI,
        T = O / 2,
        x = "ontouchstart" in window,
        v = x ? {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
            over: "touchstart",
            out: "touchend"
        } : {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
            over: "mouseover",
            out: "mouseout"
        },
        w = {
            backward: ["bl", "tl", "l"],
            forward: ["br", "tr", "r"],
            all: "tl bl tr br l r".split(" ")
        },
        Z = ["single", "double"],
        aa = ["ltr", "rtl"],
        ba = {
            acceleration: !0,
            display: "double",
            duration: 600,
            page: 1,
            gradients: !0,
            turnCorners: "bl,br",
            cornerSize: 100,
            when: null
        },
        g = {
            init: function(a) {
                C = "WebKitCSSMatrix" in window || "MozPerspective" in document.body.style;
                var b;
                Y = (b = /AppleWebkit\/([0-9\.]+)/i.exec(navigator.userAgent)) ? 534.3 < parseFloat(b[1]) : !0;
                A = X();
                var c;
                b = 0;
                var d = this.data(),
                    e = this.children();
                a = h.extend({
                    width: this.width(),
                    height: this.height(),
                    direction: this.attr("dir") || this.css("direction") || "ltr"
                }, ba, a);
                d.opts = a;
                d.pageObjs = {};
                d.pages = {};
                d.pageWrap = {};
                d.pageZoom = {};
                d.pagePlace = {};
                d.pageMv = [];
                d.zoom = 1;
                d.totalPages = a.pages || 0;
                d.eventHandlers = {
                    touchStart: h.proxy(g._touchStart, this),
                    touchMove: h.proxy(g._touchMove, this),
                    touchEnd: h.proxy(g._touchEnd, this),
                    start: h.proxy(g._eventStart, this)
                };
                if (a.when)
                    for (c in a.when)
                        q(c, a.when) && this.bind(c, a.when[c]);
                this.css({
                    position: "relative",
                    width: a.width,
                    height: a.height
                });
                this.turn("display", a.display);
                "" !== a.direction && this.turn("direction", a.direction);
                C && !x && a.acceleration && this.transform(I(0, 0, !0));
                for (c = 0; c < e.length; c++)
                    "1" != h(e[c]).attr("ignore") && this.turn("addPage", e[c], ++b);
                h(this).bind(v.down, d.eventHandlers.touchStart).bind("end", g._eventEnd).bind("pressed", g._eventPressed).bind("released", g._eventReleased).bind("flip", g._flip);
                h(this).parent().bind("start", d.eventHandlers.start);
                h(document).bind(v.move, d.eventHandlers.touchMove).bind(v.up, d.eventHandlers.touchEnd);
                this.trigger("init", [this]);
                this.turn("page", a.page);
                d.done = !0;
                return this
            },
            addPage: function(a, b) {
                var c,
                    d = !1,
                    e = this.data(),
                    f = e.totalPages + 1;
                if (e.destroying)
                    return !1;
                if (c = /\bp([0-9]+)\b/.exec(h(a).attr("class")))
                    b = parseInt(c[1], 10);
                if (b)
                    if (b == f)
                        d = !0;
                    else {
                        if (b > f)
                            throw t('Page "' + b + '" cannot be inserted');
                    }
                else
                    b = f,
                    d = !0;
                1 <= b && b <= f && (c = "double" == e.display ? b % 2 ? " odd" : " even" : "", e.done && this.turn("stop"), b in e.pageObjs && g._movePages.call(this, b, 1), d && (e.totalPages = f), e.pageObjs[b] = h(a).css({
                    "float": "left"
                }).addClass("page p" + b + c), -1 != navigator.userAgent.indexOf("MSIE 9.0") && e.pageObjs[b].hasClass("hard") && e.pageObjs[b].removeClass("hard"), g._addPage.call(this, b), g._removeFromDOM.call(this));
                return this
            },
            _addPage: function(a) {
                var b = this.data(),
                    c = b.pageObjs[a];
                if (c)
                    if (g._necessPage.call(this, a)) {
                        if (!b.pageWrap[a]) {
                            b.pageWrap[a] = h("\x3cdiv/\x3e", {
                                "class": "page-wrapper",
                                page: a,
                                css: {
                                    position: "absolute",
                                    overflow: "hidden"
                                }
                            });
                            this.append(b.pageWrap[a]);
                            b.pagePlace[a] || (b.pagePlace[a] = a, b.pageObjs[a].appendTo(b.pageWrap[a]));
                            var d = g._pageSize.call(this, a, !0);
                            c.css({
                                width: d.width,
                                height: d.height
                            });
                            b.pageWrap[a].css(d)
                        }
                        b.pagePlace[a] == a && g._makeFlip.call(this, a)
                    } else
                        b.pagePlace[a] = 0,
                        b.pageObjs[a] && b.pageObjs[a].remove()
            },
            hasPage: function(a) {
                return q(a, this.data().pageObjs)
            },
            center: function(a) {
                var b = this.data(),
                    c = h(this).turn("size"),
                    d = 0;
                b.noCenter || ("double" == b.display && (a = this.turn("view", a || b.tpage || b.page), "ltr" == b.direction ? a[0] ? a[1] || (d += c.width / 2) : d -= c.width / 2 : a[0] ? a[1] || (d -= c.width / 4) : d += c.width / 4), h(this).css({
                    marginLeft: d
                }));
                return this
            },
            destroy: function() {
                var a = this,
                    b = this.data();
                if ("prevented" != u("destroying", this)) {
                    b.destroying = !0;
                    h.each("end first flip last pressed released start turning turned zooming missing init".split(" "), function(b, d) {
                        a.unbind(d)
                    });
                    this.parent().unbind("start", b.eventHandlers.start);
                    for (h(document).unbind(v.move, b.eventHandlers.touchMove).unbind(v.up, b.eventHandlers.touchEnd); 0 !== b.totalPages;)
                        this.turn("removePage", b.totalPages);
                    b.fparent && b.fparent.remove();
                    b.shadow && b.shadow.remove();
                    this.removeData();
                    b = null;
                    return this
                }
            },
            is: function() {
                return "object" == typeof this.data().pages
            },
            zoom: function(a) {
                var b = this.data();
                if ("number" == typeof a) {
                    if (.001 > a || 100 < a)
                        throw t(a + " is not a value for zoom");
                    if ("prevented" == u("zooming", this, [a, b.zoom]))
                        return this;
                    var c = this.turn("size"),
                        d = this.turn("view"),
                        e = 1 / b.zoom,
                        f = Math.round(c.width * e * a),
                        c = Math.round(c.height * e * a);
                    b.zoom = a;
                    h(this).turn("stop").turn("size", f, c);
                    b.opts.autoCenter && this.turn("center");
                    g._updateShadow.call(this);
                    for (a = 0; a < d.length; a++)
                        d[a] && b.pageZoom[d[a]] != b.zoom && (this.trigger("zoomed", [d[a], d, b.pageZoom[d[a]], b.zoom]), b.pageZoom[d[a]] = b.zoom);
                    return this
                }
                return b.zoom
            },
            _pageSize: function(a, b) {
                var c = this.data(),
                    d = {};
                if ("single" == c.display)
                    d.width = this.width(),
                    d.height = this.height(),
                    b && (d.top = 0, d.left = 0, d.right = "auto");
                else {
                    var e = this.width() / 2,
                        f = this.height();
                    c.pageObjs[a].hasClass("own-size") ? (d.width = c.pageObjs[a].width(), d.height = c.pageObjs[a].height()) : (d.width = e, d.height = f);
                    if (b) {
                        var k = a % 2;
                        d.top = (f - d.height) / 2;
                        "ltr" == c.direction ? (d[k ? "right" : "left"] = e - d.width, d[k ? "left" : "right"] = "auto") : (d[k ? "left" : "right"] = e - d.width, d[k ? "right" : "left"] = "auto")
                    }
                }
                return d
            },
            _makeFlip: function(a) {
                var b = this.data();
                if (!b.pages[a] && b.pagePlace[a] == a) {
                    var c = "single" == b.display,
                        d = a % 2;
                    b.pages[a] = b.pageObjs[a].css(g._pageSize.call(this, a)).flip({
                        page: a,
                        next: d || c ? a + 1 : a - 1,
                        turn: this
                    }).flip("disable", b.disabled);
                    g._setPageLoc.call(this, a);
                    b.pageZoom[a] = b.zoom
                }
                return b.pages[a]
            },
            _makeRange: function() {
                var a,
                    b;
                if (!(1 > this.data().totalPages))
                    for (b = this.turn("range"), a = b[0]; a <= b[1]; a++)
                        g._addPage.call(this, a)
            },
            range: function(a) {
                var b,
                    c,
                    d,
                    e = this.data();
                a = a || e.tpage || e.page || 1;
                d = g._view.call(this, a);
                if (1 > a || a > e.totalPages)
                    throw t('"' + a + '" is not a valid page');
                d[1] = d[1] || d[0];
                1 <= d[0] && d[1] <= e.totalPages ? (a = Math.floor(2), e.totalPages - d[1] > d[0] ? (b = Math.min(d[0] - 1, a), c = 2 * a - b) : (c = Math.min(e.totalPages - d[1], a), b = 2 * a - c)) : c = b = 5;
                return [Math.max(1, d[0] - b), Math.min(e.totalPages, d[1] + c)]
            },
            _necessPage: function(a) {
                if (0 === a)
                    return !0;
                var b = this.turn("range");
                return this.data().pageObjs[a].hasClass("fixed") || a >= b[0] && a <= b[1]
            },
            _removeFromDOM: function() {
                var a,
                    b = this.data();
                for (a in b.pageWrap)
                    q(a, b.pageWrap) && !g._necessPage.call(this, a) && g._removePageFromDOM.call(this, a)
            },
            _removePageFromDOM: function(a) {
                var b = this.data();
                if (b.pages[a]) {
                    var c = b.pages[a].data();
                    l._moveFoldingPage.call(b.pages[a], !1);
                    c.f && c.f.fwrapper && c.f.fwrapper.remove();
                    b.pages[a].removeData();
                    b.pages[a].remove();
                    delete b.pages[a]
                }
                b.pageObjs[a] && b.pageObjs[a].remove();
                b.pageWrap[a] && (b.pageWrap[a].remove(), delete b.pageWrap[a]);
                g._removeMv.call(this, a);
                delete b.pagePlace[a];
                delete b.pageZoom[a]
            },
            removePage: function(a) {
                var b = this.data();
                if ("*" == a)
                    for (; 0 !== b.totalPages;)
                        this.turn("removePage", b.totalPages);
                else {
                    if (1 > a || a > b.totalPages)
                        throw t("The page " + a + " doesn't exist");
                    b.pageObjs[a] && (this.turn("stop"), g._removePageFromDOM.call(this, a), delete b.pageObjs[a]);
                    g._movePages.call(this, a, -1);
                    --b.totalPages;
                    b.page > b.totalPages ? (b.page = null, g._fitPage.call(this, b.totalPages)) : (g._makeRange.call(this), this.turn("update"))
                }
                return this
            },
            _movePages: function(a, b) {
                var c,
                    d = this,
                    e = this.data(),
                    f = "single" == e.display,
                    k = function(a) {
                        var c = a + b,
                            k = c % 2,
                            h = k ? " odd " : " even ";
                        e.pageObjs[a] && (e.pageObjs[c] = e.pageObjs[a].removeClass("p" + a + " odd even").addClass("p" + c + h));
                        e.pagePlace[a] && e.pageWrap[a] && (e.pagePlace[c] = c, e.pageObjs[c].hasClass("fixed") ? e.pageWrap[c] = e.pageWrap[a].attr("page", c) : e.pageWrap[c] = e.pageWrap[a].css(g._pageSize.call(d, c, !0)).attr("page", c), e.pages[a] && (e.pages[c] = e.pages[a].flip("options", {
                            page: c,
                            next: f || k ? c + 1 : c - 1
                        })), b && (delete e.pages[a], delete e.pagePlace[a], delete e.pageZoom[a], delete e.pageObjs[a], delete e.pageWrap[a]))
                    };
                if (0 < b)
                    for (c = e.totalPages; c >= a; c--)
                        k(c);
                else
                    for (c = a; c <= e.totalPages; c++)
                        k(c)
            },
            display: function(a) {
                var b = this.data(),
                    c = b.display;
                if (void 0 === a)
                    return c;
                if (-1 == h.inArray(a, Z))
                    throw t('"' + a + '" is not a value for display');
                switch (a) {
                case "single":
                    b.pageObjs[0] || (this.turn("stop").css({
                        overflow: ""
                    }), b.pageObjs[0] = h("\x3cdiv /\x3e", {
                        "class": "page p-temporal"
                    }).css({
                        width: this.width(),
                        height: this.height()
                    }).appendTo(this));
                    this.addClass("shadow");
                    break;
                case "double":
                    b.pageObjs[0] && (this.turn("stop").css({
                        overflow: ""
                    }), b.pageObjs[0].remove(), delete b.pageObjs[0]),
                    this.removeClass("shadow")
                }
                b.display = a;
                c && (a = this.turn("size"), g._movePages.call(this, 1, 0), this.turn("size", a.width, a.height).turn("update"));
                return this
            },
            direction: function(a) {
                var b = this.data();
                if (void 0 === a)
                    return b.direction;
                a = a.toLowerCase();
                if (-1 == h.inArray(a, aa))
                    throw t('"' + a + '" is not a value for direction');
                "rtl" == a && h(this).attr("dir", "ltr").css({
                    direction: "ltr"
                });
                b.direction = a;
                b.done && this.turn("size", h(this).width(), h(this).height());
                return this
            },
            animating: function() {
                return 0 < this.data().pageMv.length
            },
            corner: function() {
                var a,
                    b,
                    c = this.data();
                for (b in c.pages)
                    if (q(b, c.pages) && (a = c.pages[b].flip("corner")))
                        return a;
                return !1
            },
            data: function() {
                return this.data()
            },
            disable: function(a) {
                var b,
                    c = this.data(),
                    d = this.turn("view");
                c.disabled = void 0 === a || !0 === a;
                for (b in c.pages)
                    q(b, c.pages) && c.pages[b].flip("disable", c.disabled ? !0 : -1 == h.inArray(parseInt(b, 10), d));
                return this
            },
            disabled: function(a) {
                return void 0 === a ? !0 === this.data().disabled : this.turn("disable", a)
            },
            size: function(a, b) {
                if (void 0 === a || void 0 === b)
                    return {
                        width: this.width(),
                        height: this.height()
                    };
                this.turn("stop");
                var c,
                    d,
                    e = this.data();
                d = "double" == e.display ? a / 2 : a;
                this.css({
                    width: a,
                    height: b
                });
                e.pageObjs[0] && e.pageObjs[0].css({
                    width: d,
                    height: b
                });
                for (c in e.pageWrap)
                    q(c, e.pageWrap) && (d = g._pageSize.call(this, c, !0), e.pageObjs[c].css({
                        width: d.width,
                        height: d.height
                    }), e.pageWrap[c].css(d), e.pages[c] && e.pages[c].css({
                        width: d.width,
                        height: d.height
                    }));
                this.turn("resize");
                return this
            },
            resize: function() {
                var a,
                    b = this.data();
                b.pages[0] && (b.pageWrap[0].css({
                    left: -this.width()
                }), b.pages[0].flip("resize", !0));
                for (a = 1; a <= b.totalPages; a++)
                    b.pages[a] && b.pages[a].flip("resize", !0);
                g._updateShadow.call(this);
                b.opts.autoCenter && this.turn("center")
            },
            _removeMv: function(a) {
                var b,
                    c = this.data();
                for (b = 0; b < c.pageMv.length; b++)
                    if (c.pageMv[b] == a)
                        return c.pageMv.splice(b, 1), !0;
                return !1
            },
            _addMv: function(a) {
                var b = this.data();
                g._removeMv.call(this, a);
                b.pageMv.push(a)
            },
            _view: function(a) {
                var b = this.data();
                a = a || b.page;
                return "double" == b.display ? a % 2 ? [a - 1, a] : [a, a + 1] : [a]
            },
            view: function(a) {
                var b = this.data();
                a = g._view.call(this, a);
                return "double" == b.display ? [0 < a[0] ? a[0] : 0, a[1] <= b.totalPages ? a[1] : 0] : [0 < a[0] && a[0] <= b.totalPages ? a[0] : 0]
            },
            stop: function(a, b) {
                if (this.turn("animating")) {
                    var c,
                        d,
                        e,
                        f = this.data();
                    f.tpage && (f.page = f.tpage, delete f.tpage);
                    for (c = 0; c < f.pageMv.length; c++)
                        f.pageMv[c] && f.pageMv[c] !== a && (e = f.pages[f.pageMv[c]], d = e.data().f.opts, e.flip("hideFoldedPage", b), b || l._moveFoldingPage.call(e, !1), d.force && (d.next = 0 === d.page % 2 ? d.page - 1 : d.page + 1, delete d.force))
                }
                this.turn("update");
                return this
            },
            pages: function(a) {
                var b = this.data();
                if (a) {
                    if (a < b.totalPages)
                        for (var c = b.totalPages; c > a; c--)
                            this.turn("removePage", c);
                    b.totalPages = a;
                    g._fitPage.call(this, b.page);
                    return this
                }
                return b.totalPages
            },
            _missing: function(a) {
                var b = this.data();
                if (!(1 > b.totalPages)) {
                    var c = this.turn("range", a),
                        d = [];
                    for (a = c[0]; a <= c[1]; a++)
                        b.pageObjs[a] || d.push(a);
                    0 < d.length && this.trigger("missing", [d])
                }
            },
            _fitPage: function(a) {
                var b = this.data(),
                    c = this.turn("view", a);
                g._missing.call(this, a);
                if (b.pageObjs[a]) {
                    b.page = a;
                    this.turn("stop");
                    for (var d = 0; d < c.length; d++)
                        c[d] && b.pageZoom[c[d]] != b.zoom && (this.trigger("zoomed", [c[d], c, b.pageZoom[c[d]], b.zoom]), b.pageZoom[c[d]] = b.zoom);
                    g._removeFromDOM.call(this);
                    g._makeRange.call(this);
                    g._updateShadow.call(this);
                    this.trigger("turned", [a, c]);
                    this.turn("update");
                    b.opts.autoCenter && this.turn("center")
                }
            },
            _turnPage: function(a) {
                var b,
                    c,
                    d = this.data(),
                    e = d.pagePlace[a],
                    f = this.turn("view"),
                    k = this.turn("view", a);
                if (d.page != a) {
                    var l = d.page;
                    if ("prevented" == u("turning", this, [a, k])) {
                        l == d.page && -1 != h.inArray(e, d.pageMv) && d.pages[e].flip("hideFoldedPage", !0);
                        return
                    }
                    -1 != h.inArray(1, k) && this.trigger("first");
                    -1 != h.inArray(d.totalPages, k) && this.trigger("last")
                }
                "single" == d.display ? (b = f[0], c = k[0]) : f[1] && a > f[1] ? (b = f[1], c = k[0]) : f[0] && a < f[0] && (b = f[0], c = k[1]);
                e = d.opts.turnCorners.split(",");
                f = d.pages[b].data().f;
                k = f.opts;
                l = f.point;
                g._missing.call(this, a);
                d.pageObjs[a] && (this.turn("stop"), d.page = a, g._makeRange.call(this), d.tpage = c, k.next != c && (k.next = c, k.force = !0), this.turn("update"), f.point = l, "hard" == f.effect ? "ltr" == d.direction ? d.pages[b].flip("turnPage", a > b ? "r" : "l") : d.pages[b].flip("turnPage", a > b ? "l" : "r") : "ltr" == d.direction ? d.pages[b].flip("turnPage", e[a > b ? 1 : 0]) : d.pages[b].flip("turnPage", e[a > b ? 0 : 1]))
            },
            page: function(a) {
                var b = this.data();
                if (void 0 === a)
                    return b.page;
                if (!b.disabled && !b.destroying) {
                    a = parseInt(a, 10);
                    if (0 < a && a <= b.totalPages)
                        return a != b.page && (b.done && -1 == h.inArray(a, this.turn("view")) ? g._turnPage.call(this, a) : g._fitPage.call(this, a)), this;
                    throw t("The page " + a + " does not exist");
                }
            },
            next: function() {
                return this.turn("page", Math.min(this.data().totalPages, g._view.call(this, this.data().page).pop() + 1))
            },
            previous: function() {
                return this.turn("page", Math.max(1, g._view.call(this, this.data().page).shift() - 1))
            },
            peel: function(a, b) {
                var c = this.data(),
                    d = this.turn("view");
                b = void 0 === b ? !0 : !0 === b;
                !1 === a ? this.turn("stop", null, b) : "single" == c.display ? c.pages[c.page].flip("peel", a, b) : (d = "ltr" == c.direction ? -1 != a.indexOf("l") ? d[0] : d[1] : -1 != a.indexOf("l") ? d[1] : d[0], c.pages[d] && c.pages[d].flip("peel", a, b));
                return this
            },
            _addMotionPage: function() {
                var a = h(this).data().f.opts,
                    b = a.turn;
                b.data();
                g._addMv.call(b, a.page)
            },
            _eventStart: function(a, b, c) {
                var d = b.turn.data(),
                    e = d.pageZoom[b.page];
                a.isDefaultPrevented() || (e && e != d.zoom && (b.turn.trigger("zoomed", [b.page, b.turn.turn("view", b.page), e, d.zoom]), d.pageZoom[b.page] = d.zoom), "single" == d.display && c && ("l" == c.charAt(1) && "ltr" == d.direction || "r" == c.charAt(1) && "rtl" == d.direction ? (b.next = b.next < b.page ? b.next : b.page - 1, b.force = !0) : b.next = b.next > b.page ? b.next : b.page + 1), "single" == d.display && d.pageObjs[b.page].hasClass("hard") && ("l" == c && "ltr" == d.direction || "r" == c && "rtl" == d.direction ? (b.next = b.next < b.page ? b.next : b.page - 1, b.force = !0) : b.next = b.next > b.page ? b.next : b.page - 1), g._addMotionPage.call(a.target));
                g._updateShadow.call(b.turn)
            },
            _eventEnd: function(a, b, c) {
                h(a.target).data();
                a = b.turn;
                var d = a.data();
                if (c) {
                    if (c = d.tpage || d.page, c == b.next || c == b.page)
                        delete d.tpage,
                        g._fitPage.call(a, c || b.next, !0)
                } else
                    g._removeMv.call(a, b.page),
                    g._updateShadow.call(a),
                    a.turn("update")
            },
            _eventPressed: function(a) {
                a = h(a.target).data().f;
                var b = a.opts.turn;
                b.data().mouseAction = !0;
                b.turn("update");
                return a.time = (new Date).getTime()
            },
            _eventReleased: function(a, b) {
                var c;
                c = h(a.target);
                var d = c.data().f,
                    e = d.opts.turn,
                    f = e.data();
                c = "single" == f.display ? "br" == b.corner || "tr" == b.corner ? b.x < c.width() / 2 : b.x > c.width() / 2 : 0 > b.x || b.x > c.width();
                if (200 > (new Date).getTime() - d.time || c)
                    a.preventDefault(),
                    g._turnPage.call(e, d.opts.next);
                f.mouseAction = !1
            },
            _flip: function(a) {
                a.stopPropagation();
                a = h(a.target).data().f.opts;
                a.turn.trigger("turn", [a.next]);
                a.turn.data().opts.autoCenter && a.turn.turn("center", a.next)
            },
            _touchStart: function() {
                var a = this.data(),
                    b;
                for (b in a.pages)
                    if (q(b, a.pages) && !1 === l._eventStart.apply(a.pages[b], arguments))
                        return !1
            },
            _touchMove: function() {
                var a = this.data(),
                    b;
                for (b in a.pages)
                    q(b, a.pages) && l._eventMove.apply(a.pages[b], arguments)
            },
            _touchEnd: function(a) {
                var b = this.data(),
                    c;
                for (c in b.pages)
                    q(c, b.pages) && l._eventEnd.apply(b.pages[c], arguments)
            },
            calculateZ: function(a) {
                var b,
                    c,
                    d,
                    e,
                    f = this,
                    k = this.data();
                b = this.turn("view");
                var h = b[0] || b[1],
                    l = a.length - 1,
                    g = {
                        pageZ: {},
                        partZ: {},
                        pageV: {}
                    },
                    m = function(a) {
                        a = f.turn("view", a);
                        a[0] && (g.pageV[a[0]] = !0);
                        a[1] && (g.pageV[a[1]] = !0)
                    };
                for (b = 0; b <= l; b++)
                    c = a[b],
                    d = k.pages[c].data().f.opts.next,
                    e = k.pagePlace[c],
                    m(c),
                    m(d),
                    c = k.pagePlace[d] == d ? d : c,
                    g.pageZ[c] = k.totalPages - Math.abs(h - c),
                    g.partZ[e] = 2 * k.totalPages - l + b;
                return g
            },
            update: function() {
                var a,
                    b = this.data();
                if (this.turn("animating") && 0 !== b.pageMv[0]) {
                    var c,
                        d = this.turn("calculateZ", b.pageMv),
                        e = this.turn("corner"),
                        f = this.turn("view"),
                        k = this.turn("view", b.tpage);
                    for (a in b.pageWrap)
                        q(a, b.pageWrap) && (c = b.pageObjs[a].hasClass("fixed"), b.pageWrap[a].css({
                            display: d.pageV[a] || c ? "" : "none",
                            zIndex: (b.pageObjs[a].hasClass("hard") ? d.partZ[a] : d.pageZ[a]) || (c ? -1 : 0)
                        }), c = b.pages[a]) && (c.flip("z", d.partZ[a] || null), d.pageV[a] && c.flip("resize"), b.tpage ? c.flip("hover", !1).flip("disable", -1 == h.inArray(parseInt(a, 10), b.pageMv) && a != k[0] && a != k[1]) : c.flip("hover", !1 === e).flip("disable", a != f[0] && a != f[1]))
                } else
                    for (a in b.pageWrap)
                        q(a, b.pageWrap) && (d = g._setPageLoc.call(this, a), b.pages[a] && b.pages[a].flip("disable", b.disabled || 1 != d).flip("hover", !0).flip("z", null));
                return this
            },
            _updateShadow: function() {
                var a,
                    b,
                    c = this.data(),
                    d = this.width(),
                    e = this.height(),
                    f = "single" == c.display ? d : d / 2;
                a = this.turn("view");
                c.shadow || (c.shadow = h("\x3cdiv /\x3e", {
                    "class": "shadow",
                    css: p(0, 0, 0).css
                }).appendTo(this));
                for (var k = 0; k < c.pageMv.length && a[0] && a[1]; k++)
                    a = this.turn("view", c.pages[c.pageMv[k]].data().f.opts.next),
                    b = this.turn("view", c.pageMv[k]),
                    a[0] = a[0] && b[0],
                    a[1] = a[1] && b[1];
                switch (a[0] ? a[0] ? a[1] ? 3 : "ltr" == c.direction ? 2 : 1 : "rtl" == c.direction ? 4 : 2 : "ltr" == c.direction ? 1 : 2) {
                case 1:
                    "rtl" == c.direction && "single" == c.display ? c.shadow.css({
                        width: f,
                        height: e,
                        top: 0,
                        left: 0
                    }) : c.shadow.css({
                        width: f,
                        height: e,
                        top: 0,
                        left: f
                    });
                    break;
                case 2:
                    c.shadow.css({
                        width: f,
                        height: e,
                        top: 0,
                        left: 0
                    });
                    break;
                case 3:
                    c.shadow.css({
                        width: d,
                        height: e,
                        top: 0,
                        left: 0
                    });
                    break;
                case 4:
                    c.shadow.css({
                        width: f,
                        height: e,
                        top: 0,
                        left: 0
                    })
                }
            },
            _setPageLoc: function(a) {
                var b = this.data(),
                    c = this.turn("view"),
                    d = 0;
                if (a == c[0] || a == c[1])
                    d = 1;
                else if ("single" == b.display && a == c[0] + 1 || "double" == b.display && a == c[0] - 2 || a == c[1] + 2)
                    d = 2;
                if (!this.turn("animating"))
                    switch (d) {
                    case 1:
                        b.pageWrap[a].css({
                            zIndex: b.totalPages,
                            display: ""
                        });
                        break;
                    case 2:
                        b.pageWrap[a].css({
                            zIndex: b.totalPages - 1,
                            display: ""
                        });
                        break;
                    case 0:
                        b.pageWrap[a].css({
                            zIndex: 0,
                            display: b.pageObjs[a].hasClass("fixed") ? "" : "none"
                        })
                    }
                return d
            },
            options: function(a) {
                if (void 0 === a)
                    return this.data().opts;
                var b = this.data();
                h.extend(b.opts, a);
                a.pages && this.turn("pages", a.pages);
                a.page && this.turn("page", a.page);
                a.display && this.turn("display", a.display);
                a.direction && this.turn("direction", a.direction);
                a.width && a.height && this.turn("size", a.width, a.height);
                if (a.when)
                    for (var c in a.when)
                        q(c, a.when) && this.unbind(c).bind(c, a.when[c]);
                return this
            },
            version: function() {
                return "5.0.0"
            }
        },
        l = {
            init: function(a) {
                this.data({
                    f: {
                        disabled: !1,
                        hover: !1,
                        effect: this.hasClass("hard") ? "hard" : "sheet"
                    }
                });
                this.flip("options", a);
                l._addPageWrapper.call(this);
                return this
            },
            setData: function(a) {
                var b = this.data();
                b.f = h.extend(b.f, a);
                return this
            },
            options: function(a) {
                var b = this.data().f;
                return a ? (l.setData.call(this, {
                    opts: h.extend({}, b.opts, a)
                }), this) : b.opts
            },
            z: function(a) {
                var b = this.data().f;
                b.opts["z-index"] = a;
                b.fwrapper && b.fwrapper.css({
                    zIndex: a || parseInt(b.parent.css("z-index"), 10) || 0
                });
                return this
            },
            _cAllowed: function() {
                var a = this.data().f,
                    b = a.opts.page,
                    c = a.opts.turn.data(),
                    d = b % 2;
                return "hard" == a.effect ? "ltr" == c.direction ? [d ? "r" : "l"] : [d ? "l" : "r"] : "single" == c.display ? 1 == b ? "ltr" == c.direction ? w.forward : w.backward : b == c.totalPages ? "ltr" == c.direction ? w.backward : w.forward : w.all : "ltr" == c.direction ? w[d ? "forward" : "backward"] : w[d ? "backward" : "forward"]
            },
            _cornerActivated: function(a, b) {
                var c = this.data().f,
                    d = this.width(),
                    e = this.height(),
                    f = {
                        x: a.x,
                        y: a.y,
                        corner: ""
                    },
                    k = c.opts.turn.data(),
                    g = k.opts.cornerSize;
                if (0 >= f.x || 0 >= f.y || f.x >= d || f.y >= e)
                    return !1;
                var m = l._cAllowed.call(this);
                switch (c.effect) {
                case "hard":
                    if (f.x > d - g)
                        f.corner = "r";
                    else if (f.x < g)
                        f.corner = "l";
                    else
                        return !1;
                    break;
                case "sheet":
                    f.y < g ? f.corner += "t" : f.y >= e - g && (f.corner += "b"),
                    f.x <= g ? f.corner += "l" : f.x >= d - g && (f.corner += "r"),
                    2 > f.corner.length && (d = !1, "single" == c.opts.turn.data().display ? "l" !== b && k.page !== k.totalPages && (d = !0) : d = 1 == c.opts.page % 2, f.corner = d ? "r" : "l")
                }
                return f.corner && -1 != h.inArray(f.corner, m) ? f : !1
            },
            _isIArea: function(a, b) {
                var c = this.data().f.parent.offset();
                a = x && a.originalEvent ? a.originalEvent.touches[0] : a;
                return l._cornerActivated.call(this, {
                    x: a.pageX - c.left,
                    y: a.pageY - c.top
                }, b)
            },
            _c: function(a, b) {
                b = b || 0;
                switch (a) {
                case "tl":
                    return m(b, b);
                case "tr":
                    return m(this.width() - b, b);
                case "bl":
                    return m(b, this.height() - b);
                case "br":
                    return m(this.width() - b, this.height() - b);
                case "l":
                    return m(b, 0);
                case "r":
                    return m(this.width() - b, 0)
                }
            },
            _c2: function(a) {
                switch (a) {
                case "tl":
                    return m(2 * this.width(), 0);
                case "tr":
                    return m(-this.width(), 0);
                case "bl":
                    return m(2 * this.width(), this.height());
                case "br":
                    return m(-this.width(), this.height());
                case "l":
                    return m(2 * this.width(), 0);
                case "r":
                    return m(-this.width(), 0)
                }
            },
            _foldingPage: function() {
                var a = this.data().f;
                if (a) {
                    var b = a.opts;
                    if (b.turn)
                        return a = b.turn.data(), "single" == a.display ? 1 < b.next || 1 < b.page ? a.pageObjs[0] : null : a.pageObjs[b.next]
                }
            },
            _backGradient: function() {
                var a = this.data().f,
                    b = a.opts.turn.data();
                (b = b.opts.gradients && ("single" == b.display || 2 != a.opts.page && a.opts.page != b.totalPages - 1)) && !a.bshadow && (a.bshadow = h("\x3cdiv/\x3e", p(0, 0, 1)).css({
                    position: "",
                    width: this.width(),
                    height: this.height()
                }).appendTo(a.parent));
                return b
            },
            type: function() {
                return this.data().f.effect
            },
            resize: function(a) {
                var b = this.data().f,
                    c = b.opts.turn.data(),
                    d = this.width(),
                    e = this.height();
                switch (b.effect) {
                case "hard":
                    a && (b.wrapper.css({
                        width: d,
                        height: e
                    }), b.fpage.css({
                        width: d,
                        height: e
                    }), c.opts.gradients && (b.ashadow.css({
                        width: d,
                        height: e
                    }), b.bshadow.css({
                        width: d,
                        height: e
                    })));
                    break;
                case "sheet":
                    a && (a = Math.round(Math.sqrt(Math.pow(d, 2) + Math.pow(e, 2))), b.wrapper.css({
                        width: a,
                        height: a
                    }), b.fwrapper.css({
                        width: a,
                        height: a
                    }).children(":first-child").css({
                        width: d,
                        height: e
                    }), b.fpage.css({
                        width: d,
                        height: e
                    }), c.opts.gradients && b.ashadow.css({
                        width: d,
                        height: e
                    }), l._backGradient.call(this) && b.bshadow.css({
                        width: d,
                        height: e
                    })),
                    b.parent.is(":visible") && (c = D(b.parent[0]), b.fwrapper.css({
                        top: c.top,
                        left: c.left
                    }), c = D(b.opts.turn[0]), b.fparent.css({
                        top: -c.top,
                        left: -c.left
                    })),
                    this.flip("z", b.opts["z-index"])
                }
            },
            _addPageWrapper: function() {
                var a = this.data().f,
                    b = a.opts.turn.data(),
                    c = this.parent();
                a.parent = c;
                if (!a.wrapper)
                    switch (a.effect) {
                    case "hard":
                        var d = {};
                        d[A + "transform-style"] = "preserve-3d";
                        d[A + "backface-visibility"] = "hidden";
                        a.wrapper = h("\x3cdiv/\x3e", p(0, 0, 2)).css(d).appendTo(c).prepend(this);
                        a.fpage = h("\x3cdiv/\x3e", p(0, 0, 1)).css(d).appendTo(c);
                        b.opts.gradients && (a.ashadow = h("\x3cdiv/\x3e", p(0, 0, 0)).hide().appendTo(c), a.bshadow = h("\x3cdiv/\x3e", p(0, 0, 0)));
                        break;
                    case "sheet":
                        this.width(),
                        this.height(),
                        a.fparent = a.opts.turn.data().fparent,
                        a.fparent || (d = h("\x3cdiv/\x3e", {
                            css: {
                                "pointer-events": "none"
                            }
                        }).hide(), d.data().flips = 0, d.css(p(0, 0, "auto", "visible").css).appendTo(a.opts.turn), a.opts.turn.data().fparent = d, a.fparent = d),
                        this.css({
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: "auto",
                            right: "auto"
                        }),
                        a.wrapper = h("\x3cdiv/\x3e", p(0, 0, this.css("z-index"))).appendTo(c).prepend(this),
                        a.fwrapper = h("\x3cdiv/\x3e", p(c.offset().top, c.offset().left)).hide().appendTo(a.fparent),
                        a.fpage = h("\x3cdiv/\x3e", p(0, 0, 0, "visible")).css({
                            cursor: "default"
                        }).appendTo(a.fwrapper),
                        b.opts.gradients && (a.ashadow = h("\x3cdiv/\x3e", p(0, 0, 1)).appendTo(a.fpage)),
                        l.setData.call(this, a)
                    }
                l.resize.call(this, !0)
            },
            _fold: function(a) {
                var b = this.data().f,
                    c = b.opts.turn.data(),
                    d = l._c.call(this, a.corner),
                    e = this.width(),
                    f = this.height();
                switch (b.effect) {
                case "hard":
                    a.x = "l" == a.corner ? Math.min(Math.max(a.x, 0), 2 * e) : Math.max(Math.min(a.x, e), -e);
                    var k,
                        g,
                        y,
                        q,
                        p,
                        U = c.totalPages,
                        t = b.opts["z-index"] || U,
                        w = {
                            overflow: "visible"
                        },
                        u = d.x ? (d.x - a.x) / e : a.x / e,
                        v = 90 * u,
                        x = 90 > v;
                    switch (a.corner) {
                    case "l":
                        q = "0% 50%";
                        p = "100% 50%";
                        x ? (k = 0, g = 0 < b.opts.next - 1, y = 1) : (k = "100%", g = b.opts.page + 1 < U, y = 0);
                        break;
                    case "r":
                        q = "100% 50%",
                        p = "0% 50%",
                        v = -v,
                        e = -e,
                        x ? (k = 0, g = b.opts.next + 1 < U, y = 0) : (k = "-100%", g = 1 != b.opts.page, y = 1)
                    }
                    w[A + "perspective-origin"] = p;
                    b.wrapper.transform("rotateY(" + v + "deg)translate3d(0px, 0px, " + (this.attr("depth") || 0) + "px)", p);
                    b.fpage.transform("translateX(" + e + "px) rotateY(" + (180 + v) + "deg)", q);
                    b.parent.css(w);
                    x ? (u = -u + 1, b.wrapper.css({
                        zIndex: t + 1
                    }), b.fpage.css({
                        zIndex: t
                    })) : (--u, b.wrapper.css({
                        zIndex: t
                    }), b.fpage.css({
                        zIndex: t + 1
                    }));
                    c.opts.gradients && (g ? b.ashadow.css({
                        display: "",
                        left: k,
                        backgroundColor: "rgba(0,0,0," + .5 * u + ")"
                    }).transform("rotateY(0deg)") : b.ashadow.hide(), b.bshadow.css({
                        opacity: -u + 1
                    }), x ? b.bshadow.parent()[0] != b.wrapper[0] && b.bshadow.appendTo(b.wrapper) : b.bshadow.parent()[0] != b.fpage[0] && b.bshadow.appendTo(b.fpage), S(b.bshadow, m(100 * y, 0), m(100 * (-y + 1), 0), [[0, "rgba(0,0,0,0.3)"], [1, "rgba(0,0,0,0)"]], 2));
                    break;
                case "sheet":
                    var K = this,
                        L = 0,
                        C,
                        E,
                        F,
                        P,
                        B,
                        Q,
                        z = m(0, 0),
                        D = m(0, 0),
                        n = m(0, 0),
                        N = l._foldingPage.call(this),
                        R = c.opts.acceleration,
                        V = b.wrapper.height(),
                        H = "t" == a.corner.substr(0, 1),
                        G = "l" == a.corner.substr(1, 1),
                        M = function() {
                            var b = m(0, 0),
                                k = m(0, 0);
                            b.x = d.x ? d.x - a.x : a.x;
                            b.y = Y ? d.y ? d.y - a.y : a.y : 0;
                            k.x = G ? e - b.x / 2 : a.x + b.x / 2;
                            k.y = b.y / 2;
                            var g = T - Math.atan2(b.y, b.x),
                                h = Math.max(0, Math.sin(g - Math.atan2(k.y, k.x)) * Math.sqrt(Math.pow(k.x, 2) + Math.pow(k.y, 2)));
                            L = g / O * 180;
                            n = m(h * Math.sin(g), h * Math.cos(g));
                            if (g > T && (n.x += Math.abs(n.y * b.y / b.x), n.y = 0, Math.round(n.x * Math.tan(O - g)) < f))
                                return a.y = Math.sqrt(Math.pow(f, 2) + 2 * k.x * b.x), H && (a.y = f - a.y), M();
                            g > T && (b = O - g, k = V - f / Math.sin(b), z = m(Math.round(k * Math.cos(b)), Math.round(k * Math.sin(b))), G && (z.x = -z.x), H && (z.y = -z.y));
                            C = Math.round(n.y / Math.tan(g) + n.x);
                            b = e - C;
                            k = b * Math.cos(2 * g);
                            h = b * Math.sin(2 * g);
                            D = m(Math.round(G ? b - k : C + k), Math.round(H ? h : f - h));
                            c.opts.gradients && (B = b * Math.sin(g), b = l._c2.call(K, a.corner), Q = Math.min(Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2)) / e, 1), P = 100 < B ? (B - 100) / B : 0, E = m(B * Math.sin(g) / e * 100, B * Math.cos(g) / f * 100), l._backGradient.call(K) && (F = m(1.2 * B * Math.sin(g) / e * 100, 1.2 * B * Math.cos(g) / f * 100), G || (F.x = 100 - F.x), H || (F.y = 100 - F.y)));
                            n.x = Math.round(n.x);
                            n.y = Math.round(n.y);
                            return !0
                        };
                    k = function(a, d, k, g) {
                        var r = ["0", "auto"],
                            y = (e - V) * k[0] / 100,
                            n = (f - V) * k[1] / 100,
                            p = {
                                left: r[d[0]],
                                top: r[d[1]],
                                right: r[d[2]],
                                bottom: r[d[3]]
                            };
                        d = {};
                        var q = 90 != g && -90 != g ? G ? -1 : 1 : 0,
                            r = k[0] + "% " + k[1] + "%";
                        K.css(p).transform(J(g) + I(a.x + q, a.y, R), r);
                        b.fpage.css(p).transform(J(g) + I(a.x + D.x - z.x - e * k[0] / 100, a.y + D.y - z.y - f * k[1] / 100, R) + J((180 / g - 2) * g), r);
                        b.wrapper.transform(I(-a.x + y - q, -a.y + n, R) + J(-g), r);
                        p = K.find(".page").prop("outerHTML");
                        0 == b.fwrapper.find(".page-mask").length && (q = h("\x3cdiv class\x3d'page-mask'\x3e\x3c/div\x3e"), b.fwrapper.find(".p-temporal").append(q));
                        b.fwrapper.find(".page-mask").html(p);
                        b.fwrapper.transform(I(-a.x + z.x + y, -a.y + z.y + n, R) + J(-g), r);
                        c.opts.gradients && (k[0] && (E.x = 100 - E.x), k[1] && (E.y = 100 - E.y), d["box-shadow"] = "0 0 2px rgba(0,0,0,0.2)", N.css(d), S(b.ashadow, m(G ? 100 : 0, H ? 0 : 100), m(E.x, E.y), [[P, "rgba(0,0,0,0)"], [.8 * (1 - P) + P, "rgba(0,0,0," + .2 * Q + ")"], [1, "rgba(255,255,255," + .2 * Q + ")"]], 3, 0), l._backGradient.call(K) && S(b.bshadow, m(G ? 0 : 100, H ? 0 : 100), m(F.x, F.y), [[.6, "rgba(0,0,0,0)"], [.8, "rgba(0,0,0," + .3 * Q + ")"], [1, "rgba(0,0,0,0)"]], 3))
                    };
                    switch (a.corner) {
                    case "tl":
                        a.x = Math.max(a.x, 1);
                        M();
                        k(n, [1, 0, 0, 1], [100, 0], L);
                        break;
                    case "tr":
                        a.x = Math.min(a.x, e - 1);
                        M();
                        k(m(-n.x, n.y), [0, 0, 0, 1], [0, 0], -L);
                        break;
                    case "bl":
                        a.x = Math.max(a.x, 1);
                        M();
                        k(m(n.x, -n.y), [1, 1, 0, 0], [100, 100], -L);
                        break;
                    case "br":
                        a.x = Math.min(a.x, e - 1),
                        M(),
                        k(m(-n.x, -n.y), [0, 1, 1, 0], [0, 100], L)
                    }
                }
                b.point = a
            },
            _moveFoldingPage: function(a) {
                var b = this.data().f;
                if (b) {
                    var c = b.opts.turn,
                        d = c.data(),
                        e = d.pagePlace;
                    a ? (d = b.opts.next, e[d] != b.opts.page && (b.folding && l._moveFoldingPage.call(this, !1), l._foldingPage.call(this).appendTo(b.fpage), e[d] = b.opts.page, b.folding = d), c.turn("update")) : b.folding && (d.pages[b.folding] ? (c = d.pages[b.folding].data().f, d.pageObjs[b.folding].appendTo(c.wrapper)) : d.pageWrap[b.folding] && d.pageObjs[b.folding].appendTo(d.pageWrap[b.folding]), b.folding in e && (e[b.folding] = b.folding), delete b.folding)
                }
            },
            _showFoldedPage: function(a, b) {
                var c = l._foldingPage.call(this),
                    d = this.data(),
                    e = d.f,
                    f = e.visible;
                if (c) {
                    if (!f || !e.point || e.point.corner != a.corner)
                        if (c = "hover" == e.status || "peel" == e.status || e.opts.turn.data().mouseAction ? a.corner : null, f = !1, "prevented" == u("start", this, [e.opts, c]))
                            return !1;
                    if (b) {
                        var g = this,
                            d = e.point && e.point.corner == a.corner ? e.point : l._c.call(this, a.corner, 1);
                        this.animatef({
                            from: [d.x, d.y],
                            to: [a.x, a.y],
                            duration: 500,
                            frame: function(b) {
                                a.x = Math.round(b[0]);
                                a.y = Math.round(b[1]);
                                l._fold.call(g, a)
                            }
                        })
                    } else
                        l._fold.call(this, a),
                        d.effect && !d.effect.turning && this.animatef(!1);
                    if (!f)
                        switch (e.effect) {
                        case "hard":
                            e.visible = !0;
                            l._moveFoldingPage.call(this, !0);
                            e.fpage.show();
                            e.opts.shadows && e.bshadow.show();
                            break;
                        case "sheet":
                            e.visible = !0,
                            e.fparent.show().data().flips++,
                            l._moveFoldingPage.call(this, !0),
                            e.fwrapper.show(),
                            e.bshadow && e.bshadow.show()
                        }
                    return !0
                }
                return !1
            },
            hide: function() {
                var a = this.data().f,
                    b = a.opts.turn.data(),
                    c = l._foldingPage.call(this);
                switch (a.effect) {
                case "hard":
                    b.opts.gradients && (a.bshadowLoc = 0, a.bshadow.remove(), a.ashadow.hide());
                    a.wrapper.transform("");
                    a.fpage.hide();
                    break;
                case "sheet":
                    0 === --a.fparent.data().flips && a.fparent.hide(),
                    this.css({
                        left: 0,
                        top: 0,
                        right: "auto",
                        bottom: "auto"
                    }).transform(""),
                    a.wrapper.transform(""),
                    a.fwrapper.hide(),
                    a.bshadow && a.bshadow.hide(),
                    c.transform("")
                }
                a.visible = !1;
                return this
            },
            hideFoldedPage: function(a) {
                var b = this.data().f;
                if (b.point) {
                    var c = this,
                        d = b.point,
                        e = function() {
                            b.point = null;
                            b.status = "";
                            c.flip("hide");
                            c.trigger("end", [b.opts, !1])
                        };
                    if (a) {
                        var f = l._c.call(this, d.corner);
                        a = "t" == d.corner.substr(0, 1) ? Math.min(0, d.y - f.y) / 2 : Math.max(0, d.y - f.y) / 2;
                        var g = m(d.x, d.y + a),
                            h = m(f.x, f.y - a);
                        this.animatef({
                            from: 0,
                            to: 1,
                            frame: function(a) {
                                a = W(d, g, h, f, a);
                                d.x = a.x;
                                d.y = a.y;
                                l._fold.call(c, d)
                            },
                            complete: e,
                            duration: 800,
                            hiding: !0
                        })
                    } else
                        this.animatef(!1),
                        e()
                }
            },
            turnPage: function(a) {
                var b = this,
                    c = this.data().f,
                    d = c.opts.turn.data();
                a = {
                    corner: c.corner ? c.corner.corner : a || l._cAllowed.call(this)[0]
                };
                var e = c.point || l._c.call(this, a.corner, c.opts.turn ? d.opts.elevation : 0),
                    f = l._c2.call(this, a.corner);
                this.trigger("flip").animatef({
                    from: 0,
                    to: 1,
                    frame: function(c) {
                        c = W(e, e, f, f, c);
                        a.x = c.x;
                        a.y = c.y;
                        l._showFoldedPage.call(b, a)
                    },
                    complete: function() {
                        b.trigger("end", [c.opts, !0])
                    },
                    duration: d.opts.duration,
                    turning: !0
                });
                c.corner = null
            },
            moving: function() {
                return "effect" in this.data()
            },
            isTurning: function() {
                return this.flip("moving") && this.data().effect.turning
            },
            corner: function() {
                return this.data().f.corner
            },
            convertCornerData: function(a) {
                this.data();
                var b = this.width(),
                    c = this.height(),
                    d = {},
                    e = "r" == (a.originCorner || a.corner);
                if ("undefined" != typeof a.s_x && "undefined" != typeof a.s_y) {
                    var f = a.x - a.s_x,
                        g = a.y - a.s_y,
                        h = Math.abs(Math.atan(g / f)),
                        l = Math.sqrt(f * f + g * g),
                        b = e ? b : 0,
                        f = 0,
                        g = a.s_x;
                    a.y <= a.s_y ? (d.corner = e ? "br" : "bl", f = c, h = -h) : d.corner = e ? "tr" : "tl";
                    l = a.s_y + l / 2 / Math.sin(h);
                    h *= 2;
                    e || (h = -h);
                    e = (b - g) * Math.cos(h) + (f - l) * Math.sin(h) + g;
                    h = (f - l) * Math.cos(h) - (b - g) * Math.sin(h) + l;
                    if (isNaN(e) || isNaN(h))
                        if (e = a.x, "br" == d.corner || "bl" == d.corner)
                            h = c;
                        else if ("tr" == d.corner || "tr" == d.corner)
                            h = 0;
                    d.x = e;
                    d.y = h
                } else
                    d.corner = e ? "br" : "bl",
                    d.x = a.x,
                    d.y = c;
                d.s_x = a.s_x;
                d.s_y = a.s_y;
                d.o_x = a.o_x;
                d.originCorner = a.originCorner || a.corner;
                return d
            },
            _eventStart: function(a) {
                if (!h("#textcopybox").length) {
                    var b = this.data().f,
                        c = b.opts.turn;
                    b.corner || b.disabled || this.flip("isTurning") || b.opts.page != c.data().pagePlace[b.opts.page] || (b.corner = l._isIArea.call(this, a), a = !0, "sheet" == b.effect && b.corner && 1 == b.corner.corner.length && (a = b.corner.y, b.corner = l.convertCornerData.call(this, b.corner), b.corner.s_x = "l" == b.corner.originCorner ? 0 : this.width(), b.corner.s_y = a, b.corner.o_x = b.corner.x, a = !1), b.corner && l._foldingPage.call(this) ? (this.trigger("pressed", [b.point]), a && l._showFoldedPage.call(this, b.corner)) : b.corner = null)
                }
            },
            _eventMove: function(a) {
                var b = this.data().f,
                    c = b.opts.turn.data().opts.rotate,
                    d = b.opts.turn.data(),
                    e = d.opts.cornerSize;
                if (!b.disabled)
                    if (a = x ? a.originalEvent.touches : [a], b.corner) {
                        e = b.parent.offset();
                        b.corner.x = a[0].pageX - e.left;
                        b.corner.y = a[0].pageY - e.top;
                        if (b.corner && b.corner.originCorner) {
                            if ("r" == (b.corner.originCorner || b.corner))
                                if (10 < b.corner.x - b.corner.o_x && !b.corner.hasMove) {
                                    if (1 == b.opts.page || "double" == d.display)
                                        return;
                                    b.corner = l._isIArea.call(this, a, "l");
                                    b.corner.x = a[0].pageX - e.left;
                                    b.corner.y = a[0].pageY - e.top;
                                    b.corner.originCorner = "l";
                                    b.corner.s_x = 0;
                                    b.corner.s_y = b.corner.y
                                } else {
                                    if (10 > b.corner.o_x - b.corner.x && !b.corner.hasMove)
                                        return
                                }
                            else if (10 > b.corner.x - b.corner.o_x && !b.corner.hasMove)
                                return;
                            b.corner = l.convertCornerData.call(this, b.corner);
                            b.corner.hasMove = !0
                        }
                        c && (e = b.corner.x, b.corner.x = b.corner.y, b.corner.y = this.height() - e);
                        l._showFoldedPage.call(this, b.corner);
                        b.isTouchMoving = !0
                    } else if (b.hover && !this.data().effect && this.is(":visible"))
                        if (a = l._isIArea.call(this, a[0]), c = this.width(), d = this.height(), a) {
                            if ("sheet" == b.effect && 2 == a.corner.length || "hard" == b.effect)
                                if (b.status = "hover", l._foldingPage.call(this)) {
                                    if ("sheet" == b.effect) {
                                        var f = a.x,
                                            g = a.y;
                                        "tr" == a.corner ? f = c - a.x : "bl" == a.corner ? g = d - a.y : "br" == a.corner && (f = c - a.x, g = d - a.y);
                                        f > e / 1.1 || g > e / 1.1 ? (b.status = "", l.hideFoldedPage.call(this, !0)) : l._showFoldedPage.call(this, a)
                                    }
                                    "hard" == b.effect && l._showFoldedPage.call(this, a)
                                }
                        } else
                            "hover" == b.status && (b.status = "", l.hideFoldedPage.call(this, !0))
            },
            _eventEnd: function() {
                var a = this.data().f,
                    b = a.corner;
                !a.disabled && b && (1 == a.stopTurning ? l.hideFoldedPage.call(this, !0) : b.hasOwnProperty("originCorner") ? b.hasMove && "prevented" != u("released", this, [a.point || b]) && l.hideFoldedPage.call(this, !0) : "prevented" != u("released", this, [a.point || b]) && l.hideFoldedPage.call(this, !0));
                a.isTouchMoving = a.stopTurning = !1;
                a.corner = null
            },
            disable: function(a) {
                l.setData.call(this, {
                    disabled: a
                });
                return this
            },
            hover: function(a) {
                l.setData.call(this, {
                    hover: a
                });
                return this
            },
            peel: function(a, b) {
                var c = this.data().f,
                    d = c.opts.turn.data().opts.cornerSize;
                if (a) {
                    if (-1 == h.inArray(a, w.all))
                        throw t("Corner " + a + " is not permitted");
                    -1 != h.inArray(a, l._cAllowed.call(this)) && (d = l._c.call(this, a, d / 2), c.status = "peel", l._showFoldedPage.call(this, {
                        corner: a,
                        x: d.x,
                        y: d.y
                    }, b))
                } else
                    c.status = "",
                    l.hideFoldedPage.call(this, b);
                return this
            }
        };
    window.requestAnim = function() {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function(a) {
                window.setTimeout(a, 1E3 / 60)
            }
    }();
    h.extend(h.fn, {
        flip: function() {
            return N(h(this[0]), l, arguments)
        },
        turn: function() {
            return N(h(this[0]), g, arguments)
        },
        transform: function(a, b) {
            var c = {};
            b && (c[A + "transform-origin"] = b);
            c[A + "transform"] = a;
            return this.css(c)
        },
        animatef: function(a) {
            var b = this.data();
            b.effect && b.effect.stop();
            if (a) {
                a.to.length || (a.to = [a.to]);
                a.from.length || (a.from = [a.from]);
                for (var c = [], d = a.to.length, e = !0, f = this, g = (new Date).getTime(), l = function() {
                        if (b.effect && e) {
                            for (var h = [], k = Math.min(a.duration, (new Date).getTime() - g), m = 0; m < d; m++)
                                h.push(b.effect.easing(1, k, a.from[m], c[m], a.duration));
                            a.frame(1 == d ? h[0] : h);
                            k == a.duration ? (delete b.effect, f.data(b), a.complete && a.complete()) : window.requestAnim(l)
                        }
                    }, m = 0; m < d; m++)
                    c.push(a.to[m] - a.from[m]);
                b.effect = h.extend({
                    stop: function() {
                        e = !1
                    },
                    easing: function(a, b, c, d, e) {
                        return d * Math.sqrt(1 - (b = b / e - 1) * b) + c
                    }
                }, a);
                this.data(b);
                l()
            } else
                delete b.effect
        }
    });
    h.isTouch = x;
    h.mouseEvents = v;
    h.cssPrefix = X;
    h.cssTransitionEnd = function() {
        var a,
            b = document.createElement("fakeelement"),
            c = {
                transition: "transitionend",
                OTransition: "oTransitionEnd",
                MSTransition: "transitionend",
                MozTransition: "transitionend",
                WebkitTransition: "webkitTransitionEnd"
            };
        for (a in c)
            if (void 0 !== b.style[a])
                return c[a]
    };
    h.findPos = D
})(jQuery);
