<template>
  <div class="home">
    <div class="turnClass">
      <div id="flipbook" class="flipbookClass" ref="flipbookref">
        <div v-show="isOk" v-for="(item, index) in imgFiles" 
          :key="item" 
          @click="onImgClick($event, index)" 
          :class="{
            'right': index % 2 === 0 && index !== 0 && pageCanShowDouble,
            'left': index % 2 === 1 && index !== imgFiles.length - 1 && pageCanShowDouble
          }" 
          class="flipbook__item">
          <img :ref="'pageImg' + index" :src="item" alt="" :draggable="false"
            style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: -1;">

          <video v-for="(video, videoIndex) in videos" :key="'video_'+videoIndex"
            :src="video" :ref="'video_'+videoIndex"
            :style="{
              left: getVideoPosition(videoIndex).left,
              bottom: getVideoPosition(videoIndex).bottom,
              width: getVideoPosition(videoIndex).width,
            }"
            @play="onVideoPlay(index,videoIndex)"
            v-if="showVideo(index,videoIndex)" controls muted loop
            style="position: absolute;object-fit: cover;"></video>
          <!-- <iframe src="https://wx.vzan.com/live/m/vzliveplayer?type=live&vid=1448216413&liveId=942678304" 
            frameborder="0" allowfullscreen="true" 
            v-if="index==1"
            style="position: absolute;left: 11%;bottom: 11%;width: 78%;height: 40%;z-index: 99999999;"></iframe> -->
        </div>
      </div>
      <!-- 自定义翻页触发区域 -->
      <!-- <div style="position: absolute;top: 15%;right: 0;width: 20px;height: 70%;z-index: 9999;" @click="nextPage"></div>
      <div style="position: absolute;top: 15%;left: 0;width: 20px;height: 70%;z-index: 9999;" @click="prevPage"></div> -->
    </div>
    <div style="position: fixed;bottom: 0;left: 0;width: 100%;height: 52px;background-color: #fff;display: flex;align-items: center;justify-content: center;">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;max-width: 720px;box-sizing: border-box;padding: 0 5%;">
        <div style="width: 20%;height: 100%;display: flex;align-items: center;justify-content: center;" @click="toPage(1)">
        <i class="el-icon-s-home" style="font-size: 20px;color: #333;"></i>
        <span style="font-size: 12px;color: #333;margin-left: 5px;margin-top: 2px;">首页</span>
      </div>
      <div style="width: 20%;height: 100%;display: flex;align-items: center;justify-content: center;" @click="toPage(3)">
        <i class="el-icon-menu" style="font-size: 20px;color: #333;"></i>
        <span style="font-size: 12px;color: #333;margin-left: 5px;margin-top: 2px;">目录</span>
      </div>
      <div style="width: 20%;height: 60%;display: flex;align-items: center;justify-content: center;border: #eee 0px solid;border-radius: 4px;">
        {{ pageCanShowDouble && current != 1? current+1: current }} / {{ imgFiles.length }}
      </div>

      <div style="width: 13%;height: 100%;display: flex;align-items: center;justify-content: center;" @click="prevPage">
        <i class="el-icon-caret-left" style="font-size: 20px;color: #333;"></i>
      </div>
      
      <div style="width: 13%;height: 100%;display: flex;align-items: center;justify-content: center;" @click="musicPlay">
        <i class="el-icon-video-pause" v-if="musicPlayFlag" style="font-size: 20px;color: #333;"></i>
        <i class="el-icon-video-play" v-else style="font-size: 20px;color: #333;"></i>
      </div>

      <div style="width: 13%;height: 100%;display: flex;align-items: center;justify-content: center;" @click="nextPage">
        <i class="el-icon-caret-right" style="font-size: 20px;color: #333;"></i>
      </div>
      </div>

    </div>
  </div>
</template>

<script>
// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();

const $ = require('jquery');
import turn from "../assets/js/turn.js";
import { Howl } from 'howler';
import axios from 'axios';
import {
		configShare
	} from '@/utils/wxShare.js';

export default {
  name: 'PdfViewer',
  data() {
    return {
      pdfDoc: null,
      pageNum: 1,
      pageRendering: false,
      pageNumPending: null,
      imgFiles: [],
      isOk: false,
      width: '',
      height: '',
      current: 1,
      startX: 0,
      pageCanShowDouble: true, // 页面是否可以显示双页
      requiredDistance: 10, // 自定义滑动翻页的最小距离.
      videos: [
        'https://nas.alicebj.cn:14032/hifi-slc/videos/1/1.mp4',
        'https://nas.alicebj.cn:14032/hifi-slc/videos/1/2.mp4',
        'https://nas.alicebj.cn:14032/hifi-slc/videos/1/3.mp4',
        'https://nas.alicebj.cn:14032/hifi-slc/videos/1/4.mp4',
        'https://nas.alicebj.cn:14032/hifi-slc/videos/1/5.mp4',
        'https://nas.alicebj.cn:14032/hifi-slc/videos/1/6.mp4',
      ],
      sound: null, 
      musicPlayFlag: false,
      musicObj: null,
    };
  },
  // 计算属性
  computed: {
    lastImgUrl() {
      return this.imgFiles[this.imgFiles.length - 1];
    },
    videoSrc() {
      const videoContext = require.context("../assets/videos", false, /\.mp4$/);
      let videoFiles = videoContext.keys().map(key => videoContext(key));
      return videoFiles[0];
    }
  },
  methods: {
    getVideoPosition(videoIndex) {
      if(videoIndex == 0 || videoIndex == 1) return {left: '11%', bottom: '9%', width: '78%'}
      if(videoIndex == 2) return {left: '11%', bottom: '6%', width: '78%'}
      if(videoIndex == 3 || videoIndex == 4) return {left: '11%', bottom: '10%', width: '78%'}
      if(videoIndex == 5) return {left: '11%', bottom: '31%', width: '78%'}
      return {left: '11%', bottom: '31%', width: '78%'}
    },
    pauseVideo(){
      for(var i in this.videos){
        const video = this.$refs['video_' + i];
        if (video && video.pause) {
          video.pause();
        }
      }
      // if(this.musicPlayFlag) this.musicObj.play();
    },
    toPage(page){
      this.$("#flipbook").turn("page", page);
    },
    showVideo(index, videoIndex){
        // 是否显示视频
        if(index == 6 && videoIndex == 0) return true;
        if(index == 10 && videoIndex == 1) return true;
        if(index == 14 && videoIndex == 2) return true;
        if(index == 18 && videoIndex == 3) return true;
        if(index == 22 && videoIndex == 4) return true;
        if(index == 27 && videoIndex == 5) return true;
        return false;
    },
    onImgClick(e, index) {
      // 第三页为目录
      if (index === 2) {
        console.log('目录页');
        console.log(e);
        const menuImage = this.$refs['pageImg' + index][0]; // 这里的 $refs 是一个数组，获取第一个元素
        if (menuImage && menuImage.getBoundingClientRect) {
          const rect = menuImage.getBoundingClientRect();
          console.log(rect);
          const x = e.clientX - rect.left; // 点击的X坐标
          const y = e.clientY - rect.top;  // 点击的Y坐标
          // console.log(x, y);
          // 计算百分比
          const percentX = (x / rect.width) * 100;
          const percentY = (y / rect.height) * 100;
          console.log(percentX, percentY);
          if (percentX >= 22 && percentX <=92) {
            // 点击目录区域
            if (percentY >= 19 && percentY <= 25)  this.menuToPage(4) // 跳转到第1个标题对应的页面或执行相应的操作
            if (percentY >= 30 && percentY <= 36)  this.menuToPage(8) // 跳转到第2个标题对应的页面或执行相应的操作
            if (percentY >= 41 && percentY <= 47)  this.menuToPage(12) // 跳转到第3个标题对应的页面或执行相应的操作
            if (percentY >= 52 && percentY <= 59)  this.menuToPage(16) // 跳转到第4个标题对应的页面或执行相应的操作
            if (percentY >= 63 && percentY <= 69)  this.menuToPage(20) // 跳转到第5个标题对应的页面或执行相应的操作
            if (percentY >= 74 && percentY <= 80)  this.menuToPage(24) // 跳转到第6个标题对应的页面或执行相应的操作
          }
        } else {
          console.error('menuImage is not a DOM element or getBoundingClientRect is not a function');
        }
      }
    },

    async loadImages() {
      var imgFilesUrl = [];
      for (let i = 1; i <= 29; i++) {
        const imgUrl = `https://nas.alicebj.cn:14032/hifi-slc/book/img/1/${i}.png`;

        imgFilesUrl.push(imgUrl);
      }
      this.imgFiles = imgFilesUrl
      this.onTurn()
    },
    onTurn() {
      var that = this;
      // 获取当前屏幕宽高
      var screenWidth = window.innerWidth;
      var screenHeight = window.innerHeight;
      // 计算页面缩放比例
      var scale = screenWidth < 595 ? screenWidth / 595 * 1 : 1;
      // 计算页面缩放后的宽高
      this.width = 595 * scale;
      this.height = 807 * scale;
      that.pageCanShowDouble = screenWidth > 1190; // 页面是否可以显示双页
      that.$nextTick(() => {
        that.$("#flipbook").turn({
          acceleration: true, //启用硬件加速,移动端有效
          display: that.pageCanShowDouble ? "double" : "single", // 显示：single=单页，double=双页，默认双页
          duration: 800, // 翻页速度，撒开鼠标，页面的延迟
          elevation: 800, // 翻页动画的高度
          page: 1, // 默认显示第几页
          gradients: true, // 折叠处的光泽渐变，主要体现翻页的立体感、真实感
          pages: that.imgFiles.length,
          // corners: 'tl,tr,bl,br,l,r',//翻页动画开始的起始位置bl,br （左下，右下）tl,tr（左上，右上） or bl,tr（左下，右上）
          autoCenter: true,
          // // 增加拖拽区域的宽度
          // next: '.next-page',
          // previous: '.prev-page',
          // peel: "tr",// 在右上角显示角
          width: that.pageCanShowDouble ? 1190 : 595 * 0.96 * scale,
          height: 807 * scale,
          when: {
            //监听事件
            turning: function (e, page, view) {
              console.log("翻页前触发");
              console.log(e, page, view);
              $("#flipbook").turn("center");
              if (page == 1) {
                // $("#flipbook").turn('peel', 'br');
              }
              that.current = page;
              that.sound.play();
            },
            turned: function (e, page) {
              // 翻页后触发
              console.log("翻页后触发");
              console.log(e, page);
              that.$nextTick(() => {
                if (that.pageCanShowDouble && (page == 1 || page === that.imgFiles.length)) {
                  //$("#flipbook").css("margin-left", page == 1 ? '-500px' : '500px');
                }
              })
            
              // 停止视频
              that.pauseVideo();
            },
          },
        });
        // 修改flipbookClass margin-left
        if (that.pageCanShowDouble) {
          //$("#flipbook").css("margin-left", '-500px');
        }
        // 禁用点击翻页效果
        $('#flipbook').on('click', function (e) {
          e.preventDefault();
        });

        // that.$("#flipbook").bind("first", function (event) { //卷叶
        //   console.log('当前页面是第一页');
        //   that.$("#flipbook").turn("peel", "tr"); // 右上
        // });
        // that.$("#flipbook").bind("last", function (event) {
        //   console.log('当前页面是最后一页');
        //   // that.$("#flipbook").turn("peel", "bl"); //左下
        // });
        this.isOk = true;

      })

    },
    menuToPage(page) {
      $('#flipbook').turn('page', page);
    },
    nextPage() {
      $('#flipbook').turn('next');
    },
    prevPage() {
      $('#flipbook').turn('previous');
    },
    musicPlay(){
      this.musicPlayFlag = !this.musicPlayFlag;
      if(this.musicPlayFlag){
        this.musicObj.play();
      }else{
        this.musicObj.pause();
      }
    },
    onVideoPlay(){
      this.musicObj.pause();
      this.musicPlayFlag = false;
    },
    async ready2Share() {
        // 判断是不是微信h5
        if (!navigator.userAgent.toLowerCase().includes('micromessenger')) {
          console.log('不是微信h5')
          return
        }
        const response = await axios.get('/prod-api/api/signature?url=' + encodeURIComponent(window.location.href.toString()))
        	if (response.data.code == 500 || response.data.code == 301) {
            return
          }
          var data = response.data
          configShare({
            ...data
          })
			},

  },
  
  mounted() {
    document.title = "HIFI肾立场";
    this.loadImages();
    this.sound = new Howl({
      // assets/vioce/fy.mp3
      src: require('../assets/vioce/fy.mp3'),
    })
    this.musicObj = new Howl({
      src: require('../assets/vioce/music.mp3'),
      loop: true,
      volume: 0.5,
      onloaderror:() =>{
        this.musicPlayFlag = false;
      },
      onplayerror:() =>{
        this.musicPlayFlag = false;
      },
      onload:() =>{
        this.musicPlayFlag = true;
        this.musicObj.play();
      }
    })
    this.ready2Share()
    // this.musicObj.play();
  },
};
</script>

<style lang="less" scoped>
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// * {
//   touch-action: pan-y; // 启用单指垂直平移手势
// }
.home {
  //background-color: rgba(0, 0, 0, 0.55);
  background-color: #e3e3e3;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; //固定元素,不被挤压
}

.turnClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -20px;
}

.flipbookClass {
  transition: margin-left 0.5s ease-in-out;
  position: relative;
}

.flipbook__item {
  background-repeat: no-repeat; // 不重复
  background-size: cover;
  background-position: center center; //中心
  transform-origin: 50% 50%;
  max-width: 100vw;
  max-height: 100vh;
  // position: relative;
}

.right {
  background-image: linear-gradient(right, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.15) 100%);
  background: -webkit-gradient(linear, right top, left top, color-stop(0.95, rgba(0, 0, 0, 0)), color-stop(1, rgba(0, 0, 0, 0.15)));
  ;
}

.right::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0);
  z-index: -1;
}

.left {
  background-image: linear-gradient(left, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.15) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0.95, rgba(0, 0, 0, 0)), color-stop(1, rgba(0, 0, 0, 0.15)));
}

.left::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0);
  z-index: -1;
}
</style>