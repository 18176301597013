import Vue from 'vue'
import App from './App.vue'
import router from './router'; // 导入路由配置
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false

import $ from 'jquery';
Vue.use(ElementUI);


// 全局注册 jQuery
Vue.prototype.$ = $;


new Vue({
  router, // 添加路由
  render: h => h(App),
  
}).$mount('#app')
