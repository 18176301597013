import Vue from 'vue';
import Router from 'vue-router';

import PdfViewer from '@/components/PdfViewer.vue'
Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'PdfViewer',
    component: PdfViewer
  }
];

const router = new Router({
  mode: 'history', // 这里使用 history 模式
  routes
});

export default router;